<template>
  <div
    :ref="`${side}Side`"
    :class="panelClasses">
    <div class="flex-container" @click="$emit(!isShowingForm? 'panel-click': 'noop', type)">
      <div class="auth-panel-img-cont">
        <h1 class="auth-panel-copy">
          Sign up as {{ type === 'affiliate' ? 'an' : 'a' }}
        </h1>
        <h1 class="auth-panel-header">
          {{ type.slice(0, 1).toUpperCase() + type.slice(1).toLowerCase() }}
        </h1>
        <component :is="executiveComponent" class="auth-panel-image" />
      </div>
    </div>
    <div
      class="inner-panel"
      :class="[`inner-panel__${isShowingForm ? 'with-form' : 'with-program'}`]">
      <transition name="el-fade-in" :duration="300">
        <lead-source-media-object
          v-if="isShowingForm && isShowingFormDelayed"
          :org-type="type"
          :org-sub-type="subType"
          :executive-manager-title="activeManager.title"
          :executive-manager-name="activeManager.alias" />
      </transition>
      <slot name="auth-form" />
    </div>
  </div>
</template>
<script>
import AuthForm from '@/components/auth/AuthForm';
import LeadSourceMediaObject from '@/components/auth/LeadSourceMediaObject';
import AffiliateSvg from '@/assets/AffiliateSvg';
import MerchantSvg from '@/assets/MerchantSvg';
import _get from 'lodash/get';

export default {
  name: 'SignupPanel',
  components: {
    AuthForm,
    LeadSourceMediaObject,
    AffiliateSvg,
    MerchantSvg
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    inactive: {
      type: Boolean,
      default: false
    },
    side: {
      type: String,
      required: true
    },
    manager: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    subType: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      isShowingFormDelayed: _get(this.$route, 'params.param2', null) !== null
    };
  },
  computed: {
    isShowingForm() {
      return _get(this.$route, 'params.param2', null) !== null;
    },
    type() {
      return this.side === 'left' ? 'affiliate' : 'merchant';
    },
    activeManager() {
      return this.manager.type === this.type ? this.manager : {};
    },
    executiveComponent() {
      return `${this.type}-svg`;
    },
    panelClasses() {
      const classes = ['signup-panel', 'panel', `auth-view-${this.side}`];
      if (this.active) {
        classes.push('active');
      }
      if (this.inactive) {
        classes.push('inactive');
      }
      return classes;
    }
  },
  watch: {
    isShowingForm(newVal) {
      setTimeout(() => (this.isShowingFormDelayed = newVal), 300);
    }
  }
};
</script>

<style lang="scss">
.signup-panel {
  .lead-source-media-object {
    position: absolute;
    right: $--clb-mobile-padding;
    top: $--clb-mobile-padding;

    @include md-up {
      right: 40px;
      top: 40px;
    }
  }
}
</style>
