<template>
  <div class="auth-form">
    <slot name="header" />
    <el-form
      ref="form"
      :model="form.data"
      :rules="form.rules"
      novalidate
      status-icon
      @submit.native.prevent="handleFormSubmit">
      <el-form-item
        :error="errors.email"
        :rules="signup ? form.rules.email : form.rules.account"
        class="floating-label"
        prop="email">
        <div v-if="hasAccountTypeError" slot="error" class="el-form-item__error">
          {{ errors.email }} <a href="#" class="forgot-link" @click.prevent="handleSignInClick">Sign In?</a>
        </div>
        <el-input
          v-model="form.data.email"
          :placeholder="signup? 'Email': 'Email/Username'"
          type="email"
          class="form-item-input" />
      </el-form-item>
      <el-form-item
        :error="errors.password"
        :rules="signup? form.rules.password: []"
        class="floating-label tw-mb-space-4"
        prop="password">
        <el-input
          v-model="form.data.password"
          :placeholder="'Password' + (signup? ' (6+ Characters)': '')"
          type="password"
          class="form-item-input" />
        <div v-if="hasPasswordError" slot="error" class="el-form-item__error tw-leading-6">
          {{ errors.password }}
        </div>
        <a v-if="!signup" href="#" class="forgot-link tw-z-2 tw-relative" @click.prevent="handleResetEmailClick">Forgot password?</a>
      </el-form-item>
      <slot name="submit-button">
        <el-button
          :loading="processing"
          type="primary"
          native-type="submit"
          class="next-button create-account-button">
          Create Account<i class="el-icon-arrow-right" />
        </el-button>
      </slot>
    </el-form>
  </div>
</template>
<script>
import { SUB_TYPES } from '@/constants/programs';

export default {
  name: 'AuthForm',
  props: {
    errors: {
      type: Object,
      default: () => {
        return {};
      }
    },
    processing: {
      type: Boolean,
      required: true
    },
    signup: {
      type: Boolean,
      default: false
    },
    orgType: {
      type: String,
      default: null
    }
  },
  data() {
    const passwordConfirmationValidation = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Password must contain at least 6 characters'));
      } else if (value !== this.form.data.password) {
        callback(new Error('Password confirmation must match new password'));
      } else {
        callback();
      }
    };
    return {
      form: {
        data: {},
        rules: {
          email: [
            { required: true, type: 'email', message: 'Email address is not valid', trigger: ['change', 'blur'] }
          ],
          account: [
            { required: true, type: 'string', min: 4, message: 'Email/Username is not valid', trigger: ['change', 'blur'] }
          ],
          password: [
            {
              required: true,
              min: 6,
              message: 'Password must contain at least 6 characters',
              trigger: ['change']
            }
          ],
          password_confirmation: [
            {
              validator: passwordConfirmationValidation,
              trigger: ['change', 'blur']
            }
          ]
        }
      }
    };
  },
  computed: {
    manager() {
      return this.$store.getters.manager;
    },
    subType() {
      return this.$store.getters.userSubType;
    },
    hasPasswordError() {
      return this.errors.hasOwnProperty('password');
    },
    hasAccountTypeError() {
      return this.errors.hasOwnProperty('email') && this.errors.email.includes(['Account is already registered']);
    }
  },
  methods: {
    handleFormSubmit() {
      this.$refs.form.clearValidate();
      this.$refs.form.validate(valid => {
        if (valid) {
          let additionalData = {};
          if (this.orgType !== null) {
            additionalData = { type: this.orgType };
            if (this.manager.type === this.orgType) {
              additionalData.manager = this.manager.alias;
            }
            if (this.subType) {
              additionalData.sub_type = SUB_TYPES[this.subType];
            }
          }
          this.$emit('submit', Object.assign(this.form.data, additionalData));
        }
      });
    },
    handleResetEmailClick() {
      this.$store.dispatch('setResetEmail', this.form.data.email);
      this.$router.push('/signin/forgot-password');
    },
    handleSignInClick() {
      this.$router.push('/signin');
    }
  }
};
</script>

<style lang="scss">
.auth-form {
  margin: auto;
  max-width: 460px;
  width: 100%;

  .forgot-link {
    float: right;
  }

  input[type='email']:disabled {
    color: $--color-primary;
  }

  .auth-header {
    margin-bottom: 0;
  }

  .auth-sub-header {
    font-size: $--jb-font-size-small;
    margin-bottom: 32px;
    margin-top: 8px;
  }

  @include b(form) {
    @include b(form-item) {
      &.floating-label {
        margin-bottom: 32px;

        @include e(error) {
          right: 2px;
          left: 2px;
          text-align: left;
          top: 64px;
        }
      }
    }

    .el-button + .el-button {
      margin-left: 0;
    }
  }
}
</style>
