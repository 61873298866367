<template>
  <div v-if="hasLeadSource" class="lead-source-media-object">
    <img v-if="showManager" :src="managerImage" class="lead-source-media-object__image">
    <component
      :is="orgSubType"
      v-else-if="orgSubType"
      height="40px"
      class="lead-source-media-object__image"
      :class="{'lead-source-media-object__image--carousel': orgSubType === 'carousel' }" />
    <div class="lead-source-media-object__description">
      <div class="lead-source-media-object__title">
        {{ titleName }}
      </div>
      <div class="lead-source-media-object__name">
        {{ subTitleName | capitalizeAll }}
      </div>
    </div>
  </div>
</template>
<script>
import Pipeline from '@/assets/svg/brands/pipeline.svg';
import Carousel from '@/assets/svg/brands/carousel.svg';
import { SUB_TYPES } from '@/constants/programs';
import { MANAGERS } from '@/constants/managers';
const UserImages = require.context('@/assets/images/users/', true, /\.png$/);

export default {
  name: 'LeadSourceMediaObject',
  components: {
    Pipeline,
    Carousel
  },
  props: {
    executiveManagerName: {
      type: String,
      default: ''
    },
    executiveManagerTitle: {
      type: String,
      default: ''
    },
    orgType: {
      type: String,
      default: ''
    },
    orgSubType: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasLeadSource() {
      return this.managerImage || SUB_TYPES[this.orgSubType];
    },
    titleName() {
      if (this.showManager) {
        if (this.executiveManagerTitle) return this.executiveManagerTitle;
        return this.orgType === 'merchant' ? 'Account Executive' : 'Affiliate Manager';
      }

      return 'Program';
    },
    subTitleName() {
      return this.showManager
        ? this.executiveManagerName.replace(/-/, ' ')
        : this.orgSubType;
    },
    managerImage() {
      try {
        const imageName = this.executiveManagerName.replace(/[^a-z0-9\-+]+/gi, '').replace(' ', '-').toLowerCase();
        return UserImages(`./${this.orgType}/${imageName.toLowerCase()}.png`);
      } catch (e) {
        return false;
      }
    },
    showManager() {
      const managerId = this.executiveManagerName.replace(' ', '-').toLowerCase();
      return MANAGERS[managerId] && MANAGERS[managerId].program === this.orgSubType && this.managerImage;
    }
  }
};
</script>

<style lang="scss" scoped>
//Manager media object
.lead-source-media-object {
  height: 40px;

  &__image {
    border-radius: 50%;
    height: 40px;
    margin-right: 12px;
    top: -7.5px;
    width: 40px;

    &--carousel {
      fill: #0e276b;

      #Layer_2-2 > path {
        fill: $--clb-color-primary__white;
      }
    }
  }

  &__description {
    display: inline-block;
    font-size: $--jb-meta-desc-copy;
    font-weight: 600;
    line-height: 14px;
    margin-bottom: 2px;
    text-align: left;
  }

  &__title,
  &__name {
    clear: both;
    flex: 1;
    float: left;
  }

  &__title {
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.6px;
  }

  &__name {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
  }
}

// MOBILE VIEW
@media (max-width: 768px) {
  .lead-source-media-object {
    top: $--clb-space-5;
    right: $--clb-space-5;
  }
}
</style>
