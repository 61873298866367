<template>
  <div class="program-panel">
    <div
      class="program-panel__left"
      @click="handleProgramClick('pipeline')"
      @mouseover="isHoveringLeft = true"
      @mouseout="isHoveringLeft = false">
      <div class="program-panel__left-header">
        <pipeline-logo class="program-panel__left-header--logo" />
      </div>
      <div class="program-panel__left-desc">
        {{ pipelineDescription }}
      </div>
      <button class="program-panel__left-cta" :class="{'cta-border': isHoveringLeft === true }">
        Sign Up
      </button>
    </div>
    <div
      class="program-panel__right"
      @click="handleProgramClick('carousel')"
      @mouseover="isHoveringRight = true"
      @mouseout="isHoveringRight = false">
      <div class="program-panel__right-header">
        <carousel-logo class="program-panel__right-header--logo" />
      </div>
      <div class="program-panel__right-desc">
        {{ carouselDescription }}
      </div>
      <button class="program-panel__right-cta" :class="{'cta-border': isHoveringRight === true}">
        Sign Up
      </button>
    </div>
  </div>
</template>

<script>
import PipelineLogo from '@/assets/svg/brands/pipeline-white-logo.svg';
import CarouselLogo from '@/assets/svg/brands/carousel-logo.svg';
import Breakpoints from '@/mixins/Breakpoints';

const PIPELINE_DESCRIPTIONS = {
  affiliate: [
    'Pipeline is an evolution of the Jumbleberry affiliate program, built to support high volume affiliates who capitalize on market trends in health & beauty, e-commerce and lead generation verticals.',
    'Pipeline is an evolution of the Jumbleberry affiliate program, built to support high volume affiliates who capitalize on market trends in health & beauty, e-commerce and lead generation verticals. This means frequent campaign launches and high conversion rates that maximize profits.'
  ],
  merchant: [
    'Pipeline is an evolution of the Jumbleberry affiliate program, built to support health & beauty, e-commerce and lead generation merchants looking for quality, high-volume traffic.',
    'Pipeline is an evolution of the Jumbleberry affiliate program, built to support health & beauty, e-commerce and lead generation merchants looking for quality, high-volume traffic. This means frequent campaign launches and high conversion rates that maximize profits.'
  ]
};

const CAROUSEL_DESCRIPTIONS = {
  affiliate: [
    'Carousel is a managed service for media buyers and agencies that provides access to a portfolio of D2C brands and significant resources to help you build & scale paid advertising campaigns.',
    'Carousel is a managed service for media buyers to plug into to run paid advertising campaigns for some of the most inventive consumer brands. Our team provides the required resources and technology to drive campaign performance and scale. The verticals we specialize in are health & wellness, beauty, telemedicine, education, consumer apps and more.'
  ],
  merchant: [
    'Carousel is a performance marketing team that helps inventive direct-to-consumer brands with high volume customer acquisition on a performance model.',
    'Carousel is a performance marketing team that helps inventive direct-to-consumer brands with high volume customer acquisition on a performance model. We help brands build campaigns in the following verticals: health & wellness, beauty, telemedicine, education, consumer apps and more.'
  ]
};

export default {
  name: 'ProgramPanel',
  components: { CarouselLogo, PipelineLogo },
  mixins: [
    Breakpoints([992])
  ],
  props: {
    orgType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isHoveringLeft: false,
      isHoveringRight: false
    };
  },
  computed: {
    pipelineDescription() {
      return PIPELINE_DESCRIPTIONS[this.orgType][this.windowBreakpoint > 0 ? 1 : 0];
    },
    carouselDescription() {
      return CAROUSEL_DESCRIPTIONS[this.orgType][this.windowBreakpoint > 0 ? 1 : 0];
    }
  },
  methods: {
    handleProgramClick(program) {
      this.$emit('program-select', program);
    }
  }
};
</script>

<style lang="scss">
.program-panel {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $--clb-color-primary__white;

  @include md-up {
    flex-direction: column;
  }

  &__left,
  &__right {
    width: 100vw;
    height: calc(50 * var(--vh, 1vh));
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include mobile-landscape {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-areas:
        "header desc"
        "cta desc";
    }

    @include sm-up {
      width: 50vw;
    }

    .circle-logo {
      width: 100px;
      height: 73px;
      margin: $--clb-space-2;
      margin-left: 0;
      border-radius: 36px;
    }

    &-desc {
      font-size: 0.75rem;
      width: 20rem;
      margin-top: 20px;
      grid-area: desc;

      @include md-up {
        width: 28rem;
        font-size: 0.9rem;
      }

      @include lg-up {
        width: 31rem;
        font-size: 1.1rem;
      }

      @include mobile-landscape {
        width: auto;
        margin: 0 $--clb-layout-3;
        justify-self: center;
      }
    }

    &-header {
      width: 11rem;
      display: flex;
      justify-content: space-around;
      grid-area: header;

      @include md-up {
        width: 22rem;
      }

      @include mobile-landscape {
        justify-self: center;
      }

      &--logo {
        width: 16rem;
        height: 100px;
      }

      &:hover + &-cta {
        cursor: pointer;
        border-radius: 0.5rem;
        border: solid 0.125rem $--clb-color-primary__white;
        padding: 1rem 2rem;
      }
    }

    &-cta {
      grid-area: cta;

      @include mobile-landscape {
        margin: 0 $--clb-space-4;
      }
    }
  }

  &__left {
    background: rgba(42, 173, 173, 1);

    &-header--logo {
      fill: $--clb-color-primary__white;
    }

    &-cta {
      text-transform: uppercase;
      letter-spacing: 0.5rem;
      padding: 1rem 1rem;
      font-weight: 700;
      margin-top: 1.5rem;
      transition: border 0.3s ease, padding 0.3s ease;
      font-size: 0.75rem;
      background: none;
      border-radius: 0.5rem;
      border: solid 0.125rem transparent;
      color: $--clb-color-primary__white;
      cursor: pointer;

      @include md-up {
        font-size: 1rem;
      }

      @include mobile-landscape {
        margin-top: 0;
      }
    }
  }

  &__right {
    background: $--clb-color-secondary__dark;

    &-header--logo {
      fill: $--clb-color-primary__white;
    }

    &-cta {
      text-transform: uppercase;
      letter-spacing: 0.5rem;
      padding: 1rem 1rem;
      font-weight: 700;
      margin-top: 1.5rem;
      transition: border 0.3s ease, padding 0.3s ease;
      font-size: 0.75rem;
      background: none;
      border-radius: 0.5rem;
      border: solid 0.125rem transparent;
      color: $--clb-color-primary__white;
      cursor: pointer;

      @include md-up {
        font-size: 1rem;
      }

      @include mobile-landscape {
        margin-top: 0;
      }
    }
  }

  .cta-border {
    border-radius: 0.5rem;
    border: solid 0.125rem $--clb-color-primary__white;
    padding: 1rem 2rem;
  }
}
</style>
