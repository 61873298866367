<template>
  <div
    :class="{ transitioning }"
    class="auth-container signin-cont">
    <signup-panel
      v-for="panel in panels"
      :key="panel.type"
      :side="panel.side"
      :form="form"
      :manager="manager"
      :sub-type="subType"
      :active="isActivePanel(panel.type)"
      :inactive="isInactivePanel(panel.type)"
      @form-submit="handleFormSubmit"
      @panel-click="setActivePanel">
      <transition
        slot="auth-form"
        mode="out-in"
        :duration="300"
        name="fade">
        <program-panel
          v-if="isActivePanel(panel.type) && !selectedProgram"
          :key="`${panel.type}--program`"
          :org-type="panel.type"
          @program-select="handleProgramSelect" />
        <component
          :is="formComponent"
          v-if="isActivePanel(panel.type) && selectedProgram"
          :key="`${panel.type}--form`"
          :account-type="activePanelNameUpper"
          :processing="form.processing"
          :errors="form.errors"
          :org-type="panel.type"
          :is-invitation="isInvitation"
          :signup="true"
          @submit="handleFormSubmit">
          <template slot="header">
            <h1 class="auth-header">
              Sign Up
            </h1>
            <p class="auth-sub-header" v-html="subHeaderText" />
          </template>
          <template slot="submit-button">
            <el-form-item class="email-consent">
              <el-checkbox v-model="emailConsent" size="medium">
                <span class="normal">Send me helpful news and account info.</span>
              </el-checkbox>
            </el-form-item>
            <el-button
              :loading="form.processing"
              type="primary"
              native-type="submit"
              class="next-button create-account-button">
              Create {{ activePanelNameUpper }} Account <i
                class="el-icon-arrow-right" />
            </el-button>
            <div v-if="!isInvitation" class="take-me-back">
              Not {{ activePanelName === 'affiliate' ? 'an' : 'a' }} {{ activePanelName }}? <a href="javascript:;" class="attention" @click.prevent="reset">Switch</a>
              <br> Already have an account? <router-link to="/signin">
                Sign In
              </router-link>
            </div>
          </template>
        </component>
      </transition>
    </signup-panel>
  </div>
</template>
<script>
import _get from 'lodash/get';
import SignupPanel from '@/components/auth/SignupPanel';
import AffiliateSvg from '@/assets/AffiliateSvg';
import MerchantSvg from '@/assets/MerchantSvg';
import AuthForm from '@/components/auth/AuthForm';
import ProgramPanel from '@/components/auth/ProgramPanel';
import ResetPasswordForm from '@/components/auth/ResetPasswordForm';
import { handleAuthResponseError } from '@/helpers/ErrorHelper';
import Logo from '@/assets/svg/logo.svg';
import { ALLOWED_ORG_TYPES } from '@/constants/orgTypes';
import { MANAGERS } from '@/constants/managers';
import { ALLOWED_PROGRAMS, SUB_TYPES } from '@/constants/programs';
import { TERMS_AND_CONDITIONS, PRIVACY_POLICY } from '@/constants/externalLinks';

export default {
  name: 'SignupView',
  components: {
    SignupPanel,
    AuthForm,
    ProgramPanel,
    ResetPasswordForm,
    AffiliateSvg,
    MerchantSvg,
    Logo
  },
  data() {
    return {
      referrerSource: this.$route.query.source,
      transitioning: false,
      transitionTimeout: null,
      panels: [
        {
          type: 'affiliate',
          side: 'left'
        },
        {
          type: 'merchant',
          side: 'right'
        }
      ],
      emailConsent: true,
      form: {
        errors: {},
        processing: false
      },
      activePanelName: ''
    };
  },
  computed: {
    manager() {
      return this.$store.getters.manager;
    },
    subType() {
      const param2 = _get(this.$route, 'params.param2', null);
      if (MANAGERS[param2]) {
        return MANAGERS[param2].program;
      } else if (Object.keys(SUB_TYPES).includes(param2)) {
        return param2;
      } else {
        return this.$store.getters.userSubType || undefined;
      }
    },
    activePanel() {
      const param1 = _get(this.$route, 'params.param1', null);
      if (ALLOWED_ORG_TYPES.includes(param1)) {
        return param1;
      } else if (MANAGERS[param1]) {
        return MANAGERS[param1].orgType;
      } else {
        return null;
      }
    },
    selectedProgram() {
      const param = _get(this.$route, 'params.param2', null) || _get(this.$route, 'params.param1', null);
      if (ALLOWED_PROGRAMS.includes(param)) {
        return param;
      } else if (MANAGERS[param]) {
        return MANAGERS[param].program;
      } else {
        return null;
      }
    },
    activePanelNameUpper() {
      return this.activePanelName.charAt(0).toUpperCase() + this.activePanelName.slice(1);
    },
    formComponent() {
      return this.isInvitation ? 'reset-password-form' : 'auth-form';
    },
    subHeaderText() {
      return (
        'By signing up, you agree to our ' +
        `<a href="${TERMS_AND_CONDITIONS}" target="_blank">Terms</a> and to have read our ` +
        `<a href="${PRIVACY_POLICY}" target="_blank">Privacy Policy</a>.`
      );
    },
    isInvitation() {
      return this.$store.getters.passwordResetDetails !== null;
    },
    registerAction() {
      return this.isInvitation ? 'resetPassword' : 'register';
    }
  },
  watch: {
    activePanel: {
      handler(activePanel) {
        if (activePanel !== null) {
          this.activePanelName = activePanel;
        }
      },
      immediate: true
    },
    $route() {
      this.form.errors = {};

      clearTimeout(this.transitionTimeout);
      this.transitioning = true;
      this.$nextTick(() => {
        this.transitionTimeout = setTimeout(() => (this.transitioning = false), 700);
      });
    }
  },
  methods: {
    handleFormSubmit(formData) {
      this.form.errors = {};
      this.form.processing = true;
      if (formData.hasOwnProperty('type')) {
        formData.type = formData.type === 'merchant' ? 'advertiser' : formData.type;
      }
      if (this.referrerSource) {
        formData.referrer_source = this.referrerSource;
      }
      if (this.$store.getters.referralCode) {
        formData.referral_code = this.$store.getters.referralCode;
      }
      return this.$store
        .dispatch(this.registerAction, Object.assign(formData, { email_consent: this.emailConsent }))
        .then(() => {
          const redirectTo = this.$route.query.from || '/signup/splash';
          this.$router.push({ path: decodeURIComponent(redirectTo) });
        })
        .catch(error => {
          error = _get(error, 'response.data._meta.errors', null);
          this.form.errors = handleAuthResponseError(Array.isArray(error) ? error : ['An unknown error occurred']);
          this.form.processing = false;
        });
    },
    handleEmailChange(email) {
      this.savedEmail = email;
    },
    setActivePanel(type) {
      if (!type) {
        this.$router.push('/signup');
      } else if (this.selectedProgram) {
        this.$router.push(`/signup/${type}/${this.selectedProgram}`);
      } else {
        this.$router.push(`/signup/${type}`);
      }
      this.hideOrgPanel = !this.hideOrgPanel;
    },
    isActivePanel(type) {
      return this.activePanel === type;
    },
    isInactivePanel(type) {
      return this.activePanel !== null && this.activePanel !== type;
    },
    reset() {
      this.setActivePanel(null);
    },
    handleProgramSelect(program) {
      this.$router.push(`/signup/${this.activePanel}/${program}`);
    }
  }
};
</script>

<style lang="scss">
// -------- On page load state -----------
.auth-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;

  @include sm-up {
    flex-direction: row;
  }

  .logo-link-portal {
    position: absolute;
    top: 0;
    left: 0;
    margin: 2.5rem 3rem;
  }

  .panel > .flex-container {
    display: flex;
    align-items: center;
    height: calc(50 * var(--vh, 1vh));
    width: 100%;

    @include sm-up {
      height: 100vh;
    }
  }

  .signup-panel {
    position: relative;
  }

  .inner-panel,
  .inner-panel .flex-container {
    display: flex;
    align-items: flex-start;
    position: absolute;
  }

  .inner-panel {
    width: 100vw;
    background-color: $--clb-color-primary__white;

    @include sm-up {
      width: 50vw;
    }

    &__with-form {
      height: 100vh;
    }
  }

  .auth-view-left {
    width: 100vw;
    height: calc(50 * var(--vh, 1vh));
    background-color: $--clb-color-grey__white-ter;

    @include sm-up {
      width: 100vw;
      height: 100vh;
    }

    > .flex-container {
      background-color: $--clb-color-grey__white-ter;
      justify-content: center;
    }

    .auth-panel-copy {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.2rem;
      margin-bottom: 0;
      margin-top: 0;
    }

    .auth-panel-header {
      font-size: 2.4rem;
      margin-top: 0;
    }

    .auth-form {
      width: 90vw;
      padding: $--clb-layout-5 0 0 0;

      @include sm-up {
        width: 20rem;
      }

      @include md-up {
        width: 28.75rem;
        padding: $--clb-layout-4 0 0 0;
      }

      .auth-header {
        margin-top: 0;
        font-size: 3rem;
        margin-bottom: 1rem;
      }

      .auth-sub-header {
        font-size: 1rem;
        width: 18rem;
        margin: 2rem auto;
        color: $--clb-body-font;

        @include xs-up {
          width: 20rem;
        }
      }
    }

    .auth-panel-img-cont {
      max-height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $--clb-color-primary;

      .auth-panel-image {
        display: none;

        @include sm-up {
          display: flex;
          width: auto;
          height: 360px;
          max-width: calc(50vw - #{$--clb-mobile-padding * 2});
        }

        @include mobile-landscape {
          max-height: calc(50 * var(--vh, 1vh));
        }
      }
    }

    .inner-panel {
      transform: translateX(0);
      transition: opacity 0.3s ease;
    }

    &.active {
      border-right: 0.25rem solid #cccfdb;

      @include mobile-landscape {
        border: none;
      }

      > .flex-container {
        display: none;

        @include sm-up {
          display: flex;
        }
      }

      .inner-panel {
        @include sm-up {
          transform: translateX(50vw);
        }

        @media only screen and (max-height: 640px) {
          overflow-y: scroll;
        }

        transition: transform 0.3s ease;
      }
    }

    &.inactive {
      > .flex-container {
        transform: translateX(-50vw);
      }

      .inner-panel {
        transform: translateX(0);
      }
    }
  }

  .auth-view-right {
    @extend .auth-view-left;

    transform: translateX(0);
    background-color: $--clb-color-primary;

    > .flex-container {
      background-color: $--clb-color-primary;
    }

    .inner-panel {
      transform: translateX(0);
      transition: transform 0.3s ease;
    }

    .auth-panel-copy,
    .auth-panel-header {
      color: $--clb-color-primary__white;
    }

    &.active {
      border-right: 0;

      > .flex-container {
        display: none;

        @include sm-up {
          display: flex;
        }
      }

      .inner-panel {
        transform: translateX(0) translateY(calc(-50 * var(--vh, 1vh)));
        transition-duration: 0s;

        @include sm-up {
          transform: translateX(-50vw);
          transition: transform 0.3s ease;
        }
      }
    }

    &.inactive {
      > .flex-container {
        transform: translateX(50vw);
      }

      .inner-panel {
        transform: translateX(0);
      }
    }
  }

  // --------- On page load state ---------
  // -------- Styles for both sides -------
  .panel {
    .email-consent {
      text-align: left;

      .normal {
        color: $--color-text-regular !important;
      }
    }

    .take-me-back {
      width: 100%;
      font-size: 14px;
      margin-bottom: 32px;

      .attention {
        letter-spacing: 0.5px;
      }
    }

    // Active is clicked side
    &.active {
      display: flex;

      .auth-panel-img-cont {
        .auth-panel-copy {
          font-size: 1.5rem;
        }

        .auth-panel-header {
          font-size: 3rem;
        }

        .auth-panel-image {
          margin-top: 10px;
        }
      }

      // FORM
      .inner-panel {
        z-index: 12;
        pointer-events: auto;

        @include sm-up {
          z-index: 10;
          left: 0;
        }
      }

      .flex-container {
        z-index: 11;
        justify-content: center;
      }
    }
  }
}

.auth-view-right.inactive > .inner-panel__with-form > .lead-source-media-object {
  display: none;
}

.inner-panel.inner-panel__with-form > .auth-form {
  margin: auto;
}

.inner-panel.inner-panel__with-program {
  height: 100vh;

  > .auth-form {
    margin: auto;
  }
}

.transitioning {
  @include md-up {
    .panel,
    .panel > .flex-container {
      transition: opacity 0.6s ease-out;
    }
  }

  .auth-panel-copy,
  .auth-panel-header,
  .auth-panel-image {
    transition: all 0.6s cubic-bezier(0.83, -0.43, 0.21, 1.42);
  }

  .inner-panel {
    transition: background-color 0.6s ease-in;
  }
}
</style>
