<template>
  <div class="auth-form">
    <slot name="header" />
    <el-form
      ref="form"
      :model="form.data"
      :rules="form.rules"
      novalidate
      status-icon
      @submit.native.prevent="handleFormSubmit">
      <el-form-item
        :error="errors.email"
        :rules="form.rules.email"
        class="floating-label"
        prop="email">
        <el-input
          v-model="form.data.email"
          type="email"
          disabled="disabled"
          placeholder="Email"
          class="form-item-input" />
      </el-form-item>
      <el-form-item
        :error="errors.password"
        :rules="form.rules.password"
        class="floating-label"
        prop="password">
        <el-input
          v-model="form.data.password"
          placeholder="Password (6+ Characters)"
          type="password"
          class="form-item-input" />
      </el-form-item>
      <el-form-item
        v-if="!onePasswordInput"
        :error="errors.password_confirmation"
        :rules="form.rules.password_confirmation"
        class="floating-label"
        prop="password_confirmation">
        <el-input
          v-model="form.data.password_confirmation"
          placeholder="Repeat Password"
          type="password"
          class="form-item-input" />
      </el-form-item>
      <slot name="submit-button">
        <el-button
          :loading="processing"
          type="primary"
          native-type="submit"
          class="next-button create-account-button">
          {{ submitButtonText }} <i class="el-icon-arrow-right" />
        </el-button>
      </slot>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'ResetPasswordForm',
  props: {
    errors: {
      type: Object,
      default: () => {
        return {};
      }
    },
    accountType: {
      type: String,
      default: ''
    },
    processing: {
      type: Boolean,
      required: true
    },
    isInvitation: {
      type: Boolean,
      default: false
    },
    onePasswordInput: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const passwordConfirmationValidation = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Password must contain at least 6 characters'));
      } else if (value !== this.form.data.password) {
        callback(new Error('Password confirmation must match new password'));
      } else {
        callback();
      }
    };
    return {
      form: {
        data: {
          email: this.$store.getters.passwordResetDetails.user_email
        },
        rules: {
          password: [
            {
              required: true,
              min: 6,
              message: 'Password must contain at least 6 characters',
              trigger: ['change', 'blur']
            }
          ],
          password_confirmation: [
            {
              validator: passwordConfirmationValidation,
              trigger: ['change', 'blur']
            }
          ]
        }
      }
    };
  },
  computed: {
    submitButtonText() {
      return this.isInvitation ? `Create ${this.accountType} Account` : 'Reset Password';
    }
  },
  methods: {
    handleFormSubmit() {
      this.$refs.form.clearValidate();
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('submit', {
            new_password: this.form.data.password,
            token: this.$store.getters.passwordResetDetails.token
          });
        }
      });
    }
  }
};
</script>
